import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { css } from '@emotion/react';
import { Asset, AssetFile, Entry } from 'contentful';
import Image from '../../component-library/Image';
import { NavItemType, NavLinkItemType } from '../../component-library/navigation/navTypes';
import getLinkHref from './getLinkHref';
import { getLandingPageUrl } from './pageUrl';
import { NavigationBar } from '../../../utils/withAppProps';
import {
  TypeButton,
  TypeFooter,
  TypeIcon,
  TypeImageLink,
  TypeImageLinkSkeleton,
  TypeLinkGroup,
  TypeLinkSkeleton,
} from '../../../../@types/generated';
import { isTypeLink, TypeLink } from '../../../../@types/generated/TypeLink';
import { isTypeImageLink } from '../../../../@types/generated/TypeImageLink';
import { ButtonColor } from '../../component-library/buttons/PrimaryButton';

export type NavBarButtonType = {
  prompt?: string;
  colorContext: ButtonColor;
  label: string;
  variant: 'solid' | 'outline';
  href?: string;
  size: 'small' | 'large';
  trailingIconName?: 'arrow-right';
  leadingIconName?: 'arrow-left';
};

type ServerNavItemType = {
  title?: string;
  content?: string;
  image?: Asset<'WITHOUT_UNRESOLVABLE_LINKS'>;
  href?: string;
  richText?: string;
  description?: string;
};

type ServerNavLinkItemType = {
  links?: ServerNavItemType[];
  titleLink?: string;
  title: string;
  id: string;
};

function hasHref(
  link: ServerNavItemType,
): link is Omit<ServerNavItemType, 'href'> & { href: string } {
  return !!link.href;
}

function processNavigationButton(buttonFields?: TypeButton['fields']): NavBarButtonType | null {
  if (!buttonFields) return null;
  const { prompt, colorContext, label, variant, href, size, trailingIconName, leadingIconName } =
    buttonFields;
  return {
    prompt,
    colorContext,
    label,
    variant,
    href,
    size,
    trailingIconName,
    leadingIconName,
  };
}

export type NavBarServerProps = {
  imagePortrait?: Asset;
  navButton?: NavBarButtonType | null;
  mobileBottomLinks?: ServerNavLinkItemType[];
  linkGroups?: ServerNavLinkItemType[];
  logoGroup?: ServerNavLinkItemType;
  poweredByMilkywire?: boolean;
  textColor?: string | null;
};

export type NavBarClientProps = ReturnType<typeof parseNavigationBarFields>;

export function processNavigationBarData(navigationBar?: NavigationBar) {
  if (navigationBar) {
    return parseNavigationBarFields({
      navButton: processNavigationButton(navigationBar.fields?.headerButton?.fields),
      mobileBottomLinks: processServerLinkGroups(navigationBar.fields?.mobileBottomLinks),
      linkGroups: processServerLinkGroups(navigationBar.fields?.linkGroups),
      logoGroup: processServerLinkGroup(navigationBar.fields?.logoGroup),
      poweredByMilkywire: navigationBar.fields?.poweredByMilkywire,
      textColor: navigationBar.fields?.textColor,
    });
  }
}

function parseNavigationBarFields(navigationBar: NavBarServerProps) {
  return {
    imagePortrait: navigationBar?.imagePortrait,
    navButton: navigationBar?.navButton,
    linkGroups: processLinkGroups(navigationBar?.linkGroups) ?? [],
    mobileBottomLinks: processLinkGroups(navigationBar?.mobileBottomLinks ?? []),
    logoGroup: processLinkGroup(navigationBar?.logoGroup),
    poweredByMilkywire: navigationBar?.poweredByMilkywire,
    textColor: navigationBar?.textColor,
  };
}

export type FooterBarServerProps = {
  imageLandscape?: Asset;
  imagePortrait?: Asset;
  linkGroups?: ServerNavLinkItemType[];
  storeGroups?: ServerNavLinkItemType[];
  socialMedia?: ServerNavItemType[];
  paymentProviders?: Asset[];
  bottomLinks?: ServerNavItemType[];
  disclaimer?: string;
  copyright?: string;
  disableMaxWidth?: boolean;
};

export type FooterBarClientProps = ReturnType<typeof parseFooterFields>;

export function processFooterBarData(footerBar?: TypeFooter) {
  if (footerBar) {
    return parseFooterFields({
      imageLandscape: footerBar.fields?.logo?.fields?.imageLandscape,
      imagePortrait: footerBar.fields?.logo?.fields?.imagePortrait,
      linkGroups: processServerLinkGroups(footerBar?.fields.linkGroups),
      storeGroups: processServerLinkGroups(footerBar?.fields.storeGroups),
      socialMedia: processServerImageLinks(footerBar?.fields.socialMedia),
      bottomLinks: processServerLinks(footerBar?.fields.bottomLinks),
      disclaimer: footerBar.fields?.disclaimer,
      copyright: footerBar.fields?.copyright,
      paymentProviders: (footerBar.fields?.paymentProviders || [])
        .filter((icon): icon is TypeIcon => !!icon)
        .map((icon: TypeIcon) => icon?.fields.icon)
        .filter((icon): icon is Asset<'WITHOUT_UNRESOLVABLE_LINKS'> => !!icon),
    });
  }
}

function parseFooterFields(footer: FooterBarServerProps) {
  return {
    imageLandscape: footer?.imageLandscape,
    imagePortrait: footer?.imagePortrait,
    linkGroups: processLinkGroups(footer?.linkGroups) ?? [],
    storeGroups: processLinkGroups(footer?.storeGroups) ?? [],
    socialMedia: processImageLinks(footer?.socialMedia) ?? [],
    paymentProviderAssetsProps: footer?.paymentProviders?.map((asset) => ({
      id: asset.sys.id,
      fileName: asset.fields.file?.fileName,
      url: asset.fields.file?.url,
    })),
    bottomLinks: processLinks(footer?.bottomLinks ?? []),
    disclaimer: footer?.disclaimer,
    copyright: footer?.copyright,
    disableMaxWidth: footer?.disableMaxWidth,
  };
}

function processServerLinkGroups(
  linkGroups?: (TypeLinkGroup | undefined)[],
): ServerNavLinkItemType[] | undefined {
  return linkGroups?.map((linkGroup) => processServerLinkGroup(linkGroup));
}

function processServerLinkGroup(linkGroup?: TypeLinkGroup): ServerNavLinkItemType {
  return {
    links: processServerLinks(linkGroup?.fields.links) ?? [],
    titleLink: getLinkHref(linkGroup?.fields.titleLink),
    title: linkGroup?.fields.title ?? '',
    id: linkGroup?.sys.id ?? '',
  };
}

export const NavigationAsset = ({
  fileName,
  url,
  alt,
}: {
  fileName?: string | AssetFile;
  url?: string | AssetFile;
  alt: string;
}) => (
  <Image
    fileName={typeof fileName === 'string' ? fileName : fileName?.fileName}
    url={typeof url === 'string' ? url : url?.url || ''}
    wrapperCss={css`
      display: flex;
    `}
    alt={alt}
  />
);

export function processLinkGroups(
  linkGroups: ServerNavLinkItemType[] | undefined,
): NavLinkItemType[] {
  return linkGroups?.map((linkGroup) => processLinkGroup(linkGroup)) ?? [];
}

export function processLinkGroup(linkGroup: ServerNavLinkItemType | undefined): NavLinkItemType {
  return {
    links: processLinks(linkGroup?.links ?? []),
    titleLink: linkGroup?.titleLink,
    title: linkGroup?.title ?? '',
    id: linkGroup?.id ?? '',
  };
}

function processServerImageLinks(
  imageLinks?: (TypeImageLink | undefined)[],
): ServerNavItemType[] | undefined {
  return imageLinks
    ?.filter((imageLink): imageLink is TypeImageLink => !!imageLink)
    .map((imageLink) => {
      const href = imageLink.fields?.pageLink
        ? getLandingPageUrl({
            slug: imageLink.fields.pageLink.fields.slug,
            pageTags: imageLink.fields.pageLink.fields.tags,
          })
        : imageLink.fields?.link;
      const image = imageLink.fields?.image;
      return {
        href,
        title: imageLink.fields.title,
        content: imageLink.fields.title,
        image,
        richText:
          imageLink.fields?.richText && documentToPlainTextString(imageLink.fields.richText),
        description:
          imageLink.fields?.description && documentToPlainTextString(imageLink.fields.description),
      };
    });
}

export function processImageLinks(imageLinks?: ServerNavItemType[]): NavItemType[] | undefined {
  return imageLinks?.filter(hasHref).map((imageLink) => {
    return {
      href: imageLink.href,
      title: imageLink.title,
      content: imageLink.title,
      assetProps: imageLink.image?.fields.file && {
        fileName: imageLink.image?.fields.file.fileName,
        url: imageLink.image?.fields.file.url,
        alt: imageLink.image?.fields.description ?? imageLink.image?.fields.title ?? '',
      },
      richText: imageLink.richText,
      description: imageLink.description,
    };
  });
}

type Link = Entry<TypeImageLinkSkeleton | TypeLinkSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>;

function processServerLinks(
  links: (Link | undefined)[] | undefined,
): ServerNavItemType[] | undefined {
  return links
    ?.filter((link): link is Link => !!link)
    ?.filter((link): link is TypeLink | TypeImageLink => isTypeLink(link) || isTypeImageLink(link))
    .map((link) => {
      if (isTypeLink(link)) {
        return {
          title: link.fields?.identifier,
          content: link.fields?.identifier,
          href: getLinkHref(link),
        };
      } else {
        const href = link.fields?.pageLink
          ? getLandingPageUrl({
              slug: link.fields.pageLink.fields.slug,
              pageTags: link.fields.pageLink.fields.tags,
            })
          : link.fields?.link;
        const image = link.fields?.image;
        return {
          title: link.fields?.title,
          content: link.fields?.title,
          href,
          image,
          richText: link.fields?.richText && documentToPlainTextString(link.fields.richText),
          description:
            link.fields?.description && documentToPlainTextString(link.fields.description),
        };
      }
    });
}

export function processLinks(links: ServerNavItemType[]): NavItemType[] | undefined {
  return links?.filter(hasHref).map((link) => {
    return {
      title: link.title,
      content: link.title,
      href: link.href,
      assetProps: link.image?.fields.file && {
        fileName: link.image.fields.file.fileName,
        url: link.image.fields.file.url,
        alt: link.image.fields.description ?? link.image.fields.title ?? '',
      },
      richText: link.richText,
      description: link.description,
    };
  });
}
