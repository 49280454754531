import styled from '@emotion/styled';
import Link from 'next/link';
import { breakpoints, colors, spacing } from '../../../../utils/styleguide';
import { NavigationAsset } from '../../../contentful-elements/util/navigation';
import Typography from '../../text/Typography';
import { NavLinkItemType } from '../navTypes';

const NavigationLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]}px;

  ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

const LinkItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;

  flex: 1;
`;

const StoreGroupsItemLinks = styled.div`
  display: flex;

  a {
    margin-right: ${spacing[4]}px;
    :last-child {
      margin-right: 0;
    }
    ${breakpoints.tablet} {
      margin-right: 0;
    }
  }
  img {
    height: ${spacing[5]}px;
    object-fit: contain;
  }

  > * {
    ${breakpoints.tablet} {
      :last-child {
        > div {
          margin-top: ${spacing[3]}px;
        }
      }
    }
  }
  ${breakpoints.tablet} {
    flex-direction: column;
  }
`;

const LinkText = styled.a`
  display: block;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
`;

type LinksNavigationProps = {
  textLinks?: NavLinkItemType[];
  imageLinks?: NavLinkItemType[];
};

const NavigationLinks = ({ textLinks, imageLinks }: LinksNavigationProps) => {
  return (
    <NavigationLinkContainer>
      {textLinks?.map(({ links, id, title }) => (
        <LinkItem key={id}>
          <Typography variant="overlineBold" color={colors.blackSecondary}>
            {title}
          </Typography>
          {links?.map(({ title, content, href }, index) => {
            return (
              <Link href={href} passHref={!!href} key={index} legacyBehavior>
                <LinkText aria-label={title}>
                  <Typography color={colors.blackSecondary} hoverColor={colors.blackPrimary}>
                    {content}
                  </Typography>
                </LinkText>
              </Link>
            );
          })}
        </LinkItem>
      ))}

      {imageLinks?.map(({ links, id, title }) => (
        <LinkItem key={id}>
          <Typography variant="overlineBold">{title}</Typography>
          <StoreGroupsItemLinks>
            {links?.map(({ title, assetProps, href }, index) => {
              return (
                <Link href={href} key={index} aria-label={title}>
                  {assetProps && <NavigationAsset {...assetProps} />}
                </Link>
              );
            })}
          </StoreGroupsItemLinks>
        </LinkItem>
      ))}
    </NavigationLinkContainer>
  );
};

export default NavigationLinks;
