import { TypePageSection } from '../../../../@types/generated';
import getSettingsFromSection from '../../../utils/getSettingsFromSection';
import { colors } from '../../../utils/styleguide';
import buttonToCompatibleProps from './buttonToCompatibleProps';
import orientedMediaToCompatibleProps from './orientedMediaToCompatibleProps';

const sectionToCompatibleProps = ({ section }: { section: TypePageSection }) => {
  const {
    background,
    backgroundEntry,
    buildingBlock,
    orientedMedia,
    title,
    description,
    help,
    reversed,
    reversedMobile,
    image,
    variant,
  } = section.fields || {};

  const media = orientedMediaToCompatibleProps({ orientedMedia, image });
  const button = buttonToCompatibleProps({ button: buildingBlock?.fields.button });
  const backgroundMedia = orientedMediaToCompatibleProps({
    orientedMedia: backgroundEntry?.fields.orientedMedia,
  });

  return {
    id: getSectionId({ section }),
    title: buildingBlock?.fields.title || title,
    description: buildingBlock?.fields.description || description,
    overline: buildingBlock?.fields.preheader,
    withCheckmarks: buildingBlock?.fields.withCheckmarks,
    media,
    help,
    reversed,
    reversedMobile,
    backgroundImage: backgroundMedia.image || background,
    backgroundImageLandscape: backgroundMedia.imageLandscape,
    backgroundImagePortrait: backgroundMedia.imagePortrait,
    backgroundColor: getBackgroundColor({ backgroundEntry }),
    backgroundLinear: backgroundEntry?.fields.linear,
    backgroundOverlay: backgroundEntry?.fields.overlay,
    button,
    logos: buildingBlock?.fields.logos,
    variant,
  };
};

const getBackgroundColor = ({
  backgroundEntry,
}: Pick<TypePageSection['fields'], 'backgroundEntry'>): string | undefined => {
  if (backgroundEntry?.fields.color) {
    return colors[backgroundEntry.fields.color];
  }

  return undefined;
};

export const getSectionId = ({ section }: { section: TypePageSection }) => {
  const settings = getSettingsFromSection(section);

  // This is currently used by the form and hero-donation sections
  if ('id' in settings && typeof settings.id === 'string') return settings.id;

  return section.sys.id;
};

export default sectionToCompatibleProps;
