import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isString from 'lodash/isString';
import Link from 'next/link';
import { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react';
import { EVENT_NAMES, sendEvent } from '../../../utils/analytics/analytics';
import { colors, spacing } from '../../../utils/styleguide';
import Icon, { IconNames } from '../Icon';
import Typography from '../text/Typography';
import ThreeDots from '../ThreeDots';

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const variantStyles = {
  purple: {
    solid: css`
      background-color: ${colors.purple400};
      color: ${colors.white};
      border: 1px solid ${colors.purple400};

      :hover {
        border: 1px solid ${colors.purple500};
        background-color: ${colors.purple500};
      }

      :focus {
        background-color: ${colors.purple500};
        border: 1px solid ${colors.purple600};
      }

      :active {
        background-color: ${colors.purple600};
      }

      & .mw-button-loading {
        background-color: ${colors.purple400};
        border: 1px solid ${colors.purple400};
        color: ${colors.white};
      }
    `,
    outline: css`
      background-color: transparent;
      color: ${colors.purple400};
      border: 1px solid ${colors.purple400};

      :hover {
        background-color: ${colors.purple400};
        color: ${colors.white};
      }

      :focus {
        background-color: ${colors.purple500};
        color: ${colors.white};
        border-color: ${colors.purple600};
      }

      :active {
        background-color: ${colors.purple600};
        color: ${colors.white};
      }

      & .mw-button-loading {
        background-color: ${colors.purple500};
        border: 1px solid ${colors.purple500};
        color: ${colors.white};
      }
    `,
  },
  white: {
    solid: css`
      background-color: ${colors.white};
      color: ${colors.black};
      border: 1px solid ${colors.white};

      :hover {
        border: 1px solid ${colors.grey01};
        background-color: ${colors.grey01};
      }

      :focus {
        background-color: ${colors.white};
        border: 1px solid ${colors.black};
      }

      :active {
        background-color: ${colors.grey02};
      }

      & .mw-button-loading {
        background-color: ${colors.white};
        border: 1px solid ${colors.white};
        color: ${colors.black};
      }
    `,
    outline: css`
      background-color: transparent;
      color: ${colors.white};
      border: 1px solid ${colors.white};

      :hover {
        background-color: ${colors.white};
        color: ${colors.black};
      }

      :focus {
        background-color: ${colors.white};
        color: ${colors.black};
        border-color: ${colors.black};
      }

      :active {
        background-color: ${colors.grey01};
        color: ${colors.black};
      }

      & .mw-button-loading {
        background-color: ${colors.white};
        border: 1px solid ${colors.white};
        color: ${colors.black};
      }
    `,
  },
};

export type ButtonColor = keyof typeof variantStyles;
export type ButtonVariant = 'solid' | 'outline';

const getStyle = (variant: ButtonVariant, color: ButtonColor) => {
  const variantData = variantStyles[color] || {};
  return variantData[variant] ? variantData[variant].styles : null;
};

type CommonProps = {
  label?: ReactNode;
  children?: ReactNode;
  className?: string;
  variant?: ButtonVariant;
  color?: ButtonColor;
  loading?: boolean;
  disabled?: boolean;
  size?: 'small' | 'large';
  leadingIconName?: IconNames;
  trailingIconName?: IconNames;
  href?: string | null;
  style?: CSSProperties;
  textColor?: string;
  colorOverride?: string;
};

type HTMLProps = Omit<ButtonHTMLAttributes<HTMLElement>, 'value'>;

type ButtonClickProps = {
  tag?: string;
};

type LinkButtonProps = {
  href?: string;
  externalLink?: boolean;
  replace?: boolean;
  openLinkInNewTab?: boolean;
};

export type ButtonProps = (ButtonClickProps | LinkButtonProps) & HTMLProps & CommonProps;

const PrimaryButton = ({
  label,
  color = 'purple',
  variant = 'solid',
  disabled,
  className,
  loading,
  size = 'large',
  children,
  leadingIconName,
  trailingIconName,
  textColor: overrideTextColor, // Note: Ignore this prop. Used for styling in the PPCPrimaryButton
  colorOverride, // Note: Ignore this prop. Used for styling in the PPCPrimaryButton
  ...commonProps
}: ButtonProps) => {
  const { onClick, tag, ...linkProps } = commonProps as ButtonClickProps & HTMLProps;
  const { href, openLinkInNewTab, externalLink, replace, ...rest } = linkProps as LinkButtonProps &
    HTMLProps;

  const onClickWithAnalytics: HTMLProps['onClick'] = (event) => {
    if (loading || disabled) {
      return;
    }

    sendEvent({
      name: EVENT_NAMES.buttonClick,
      properties: {
        name: isString(label) ? label : undefined,
        'from path': window.location.pathname,
        'from query': window.location.search,
        to: href || '',
      },
    });

    return onClick?.(event);
  };

  const isLink = href && !disabled;
  const textColor = 'inherit';

  const button = (
    <Typography
      css={css`
        display: inline-flex;
        border: none;
        justify-content: center;
        align-items: center;
        position: relative;

        box-sizing: border-box;
        border-radius: 2px;
        cursor: ${disabled || loading ? 'default' : 'pointer'};
        -webkit-appearance: none;

        ${size === 'large'
          ? css`
              padding: ${spacing[3]}px ${spacing[4]}px;
            `
          : css`
              padding: ${spacing[1]}px ${spacing[3]}px;
            `}
        ${getStyle(variant, color)}

        transition: all cubic-bezier(0.52, 0.04, 0.44, 1.32) 240ms;

        :disabled {
          background-color: ${colors.grey03};
          border-color: ${colors.grey03};
          color: ${colors.whiteDisabled};
        }
      `}
      className={className}
      color={textColor}
      disabled={disabled || loading}
      href={href}
      onClick={onClickWithAnalytics}
      tag={isLink ? 'a' : tag}
      target={openLinkInNewTab ? '_blank' : undefined}
      variant={size === 'large' ? 'buttonLarge' : 'buttonSmall'}
      useBuiltInMargins
      {...rest}
    >
      {leadingIconName && (
        <Icon
          name={leadingIconName}
          size={16}
          stroke={textColor}
          css={css`
            margin-right: ${spacing[0]}px;
          `}
        />
      )}
      {label || children}
      {loading && (
        <LoadingContainer className="mw-button-loading">
          <ThreeDots />
        </LoadingContainer>
      )}
      {trailingIconName && (
        <Icon
          name={trailingIconName}
          size={16}
          stroke={'currentColor'}
          css={css`
            margin-left: ${spacing[0]}px;
          `}
        />
      )}
    </Typography>
  );

  if (isLink && !externalLink && !href?.startsWith('#') && !href?.startsWith('http')) {
    return (
      <Link href={href as string} passHref replace={replace} legacyBehavior>
        {button}
      </Link>
    );
  }

  return button;
};

export default PrimaryButton;
