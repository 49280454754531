export function getLandingPageUrl({ slug, pageTags = [] }: { slug: string; pageTags?: string[] }) {
  const tags = pageTags.map((tag: string) => tag.toLocaleLowerCase());
  if (tags.includes('give one')) {
    return `/klarna/${slug}`;
  }
  if (tags.includes('top level')) {
    return `/${slug}`;
  }

  return `/${slug}`; // No tag, we rely on the contentful slug to match an actual hardcoded page counterpart
}
