// https://developers.google.com/analytics/devguides/collection/gtagjs/events
import { PaymentType } from '../../../types/PaymentTypes';
import { state } from './index';

export type GTM_EVENTS =
  | ['purchase', PURCHASE_EVENT_PROPERTIES]
  | ['checkout', CHECKOUT_EVENT_PROPERTIES]
  | ['addToCart', ADDTOCART_EVENT_PROPERTIES]
  | ['productClick', PRODUCTCLICK_EVENT_PROPERTIES]
  | ['productView', PRODUCTVIEW_EVENT_PROPERTIES]
  | ['pageView'];

type DonationItemType = 'Category' | 'Impacter';

type GET_EVENT_PROPERTIES = {
  amountPerItem?: number;
  paymentType?: PaymentType;
  donationItemType?: DonationItemType;
  items: {
    id?: string | number;
    name?: string;
    category?: string;
  }[]; // impacter or category data
};

const getEventProducts = ({
  amountPerItem,
  donationItemType,
  items,
  paymentType,
}: GET_EVENT_PROPERTIES) => {
  if (donationItemType === 'Category') {
    return items.map(({ id, name, category }) => ({
      id,
      name,
      category: category ?? name,
      price: amountPerItem,
      variant: paymentType,
      dimension2: 'Category',
    }));
  }
  if (donationItemType === 'Impacter') {
    return items.map(({ id, name, category }) => ({
      id,
      name: category,
      category,
      price: amountPerItem,
      variant: paymentType,
      dimension1: name,
      dimension2: 'Impacter',
    }));
  }
  return [];
};

type PURCHASE_EVENT_PROPERTIES = {
  currencyCode: string;
  paymentFlowId?: string;
  amount?: number;
  paymentType?: PaymentType;
  donationItemType?: DonationItemType;
  items: {
    id: string | number;
    name: string;
    category?: string;
  }[]; // impacter or category data
};

export const purchase = ({
  currencyCode,
  paymentFlowId,
  amount,
  paymentType,
  items,
  donationItemType,
}: PURCHASE_EVENT_PROPERTIES) => {
  const amountPerItem = amount ? amount / items.length : 0;
  const products = getEventProducts({ amountPerItem, paymentType, items, donationItemType });
  const event = {
    event: 'purchase',
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id: paymentFlowId,
          revenue: amount,
          Tax: '0',
        },
        products,
      },
    },
  };

  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
    return;
  }

  state.queue.push({ name: 'purchase', event: { ecommerce: null } });
  state.queue.push({ name: 'purchase', event });
};

type CHECKOUT_EVENT_PROPERTIES = {
  currencyCode: string;
  amount?: number;
  paymentType?: PaymentType;
  donationItemType?: DonationItemType;
  items: {
    id: string | number;
    name: string;
    category?: string;
  }[]; // impacter or category data
};

export const checkout = ({
  currencyCode,
  amount,
  paymentType,
  items,
  donationItemType,
}: CHECKOUT_EVENT_PROPERTIES) => {
  const amountPerItem = amount ? amount / items.length : 0;
  const products = getEventProducts({ amountPerItem, paymentType, items, donationItemType });
  const event = {
    event: 'checkout',
    ecommerce: {
      currencyCode: currencyCode,
      checkout: {
        products,
      },
    },
  };

  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
    return;
  }

  state.queue.push({ name: 'checkout', event: { ecommerce: null } });
  state.queue.push({ name: 'checkout', event });
};

type ADDTOCART_EVENT_PROPERTIES = {
  currencyCode?: string;
  amount?: number;
  paymentType?: PaymentType;
  donationItemType?: DonationItemType;
  items: {
    id: string | number;
    name: string;
    category?: string;
  }[]; // impacter or category data
};

export const addToCart = ({
  currencyCode,
  amount,
  paymentType,
  items,
  donationItemType,
}: ADDTOCART_EVENT_PROPERTIES) => {
  const amountPerItem = amount ? amount / items.length : 0;
  const products = getEventProducts({ amountPerItem, paymentType, items, donationItemType });
  const event = {
    event: 'addToCart',
    ecommerce: {
      currencyCode: currencyCode,
      add: {
        products,
      },
    },
  };

  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
    return;
  }

  state.queue.push({ name: 'addToCart', event: { ecommerce: null } });
  state.queue.push({ name: 'addToCart', event });
};

type PRODUCTCLICK_EVENT_PROPERTIES = {
  donationItemType?: DonationItemType;
  items: {
    id?: string | number;
    name?: string;
    category?: string;
  }[];
};

export const productClick = ({ donationItemType, items }: PRODUCTCLICK_EVENT_PROPERTIES) => {
  const products = getEventProducts({ items, donationItemType });
  const event = {
    event: 'productClick',
    ecommerce: {
      click: {
        products,
      },
    },
  };

  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
    return;
  }
  state.queue.push({ name: 'productClick', event: { ecommerce: null } });
  state.queue.push({ name: 'productClick', event });
};

type PRODUCTVIEW_EVENT_PROPERTIES = {
  currencyCode?: string;
  amount?: number;
  paymentType?: PaymentType;
  items: {
    id: string | number;
    name: string;
    category?: string;
  }[]; // impacter or category data
  donationItemType?: DonationItemType;
};

export const productView = ({
  currencyCode,
  amount,
  paymentType,
  items,
  donationItemType,
}: PRODUCTVIEW_EVENT_PROPERTIES) => {
  const amountPerItem = amount ? amount / items.length : 0;
  const products = getEventProducts({ amountPerItem, paymentType, items, donationItemType });
  const event = {
    event: 'productView',
    ecommerce: {
      currencyCode: currencyCode,
      detail: {
        products,
      },
    },
  };

  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
    return;
  }

  state.queue.push({ name: 'productView', event: { ecommerce: null } });
  state.queue.push({ name: 'productView', event });
};

export const pageView = () => {
  const event = {
    event: 'pageView',
  };

  if (window.dataLayer) {
    window.dataLayer.push(event);
    return;
  }

  state.queue.push({ name: 'pageView', event });
};
