abstract class ErrorWithStatus extends Error {
  public status: number | undefined = 500;
}

// FIXME: Match API of JS Error constructor (message first)
export class ApiError extends ErrorWithStatus {
  constructor(
    public type: string,
    public status: number,
    public errorId: string,
    public errorCode: string,
    public customerMessage: string,
    public message: string,
  ) {
    super(`${type}: ${status} ${message}`);
  }
  toClient() {
    return {
      name: 'ApiError',
      message: this.message,
      status: this.status,
      errorId: this.errorId,
      errorCode: this.errorCode,
      type: this.type,
      customerMessage: this.customerMessage,
    };
  }
}

export class UrlError extends ErrorWithStatus {
  public status: number;
  constructor(public message: string, opts: { status: number }) {
    super(message);
    this.status = opts.status;
  }
  toClient() {
    return {
      name: 'UrlError',
      message: this.message,
      status: this.status,
    };
  }
}

export class FetchError extends Error {
  public type: string;
  public status: number;
  public body?: { message?: string; errorMessage?: string };

  constructor(
    message: string,
    type: string,
    status: number,
    body?: { message?: string; errorMessage?: string },
  ) {
    super(message);

    this.type = type;
    this.status = status;
    this.body = body;
  }
}

export const isUrlError = (error: unknown): error is UrlError => error instanceof UrlError;

// FIXME: this should only need to check instanceOf
export const isApiError = (error: unknown): error is ApiError =>
  (error as ApiError).status !== undefined;
