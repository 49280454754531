import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { breakpoints, spacing } from '../../../utils/styleguide';

export const MAX_WIDTH = 1440;
export const Container = styled.div<{
  disableMaxWidth?: boolean;
  maxWidth?: number;
  paddingX?: number;
}>`
  ${({ disableMaxWidth, maxWidth, paddingX = 0 }) =>
    disableMaxWidth
      ? ''
      : css`
          max-width: ${maxWidth ?? MAX_WIDTH}px;
          padding: 0 ${paddingX}px;
          overflow: hidden;
          @media (min-width: ${maxWidth ?? MAX_WIDTH + 1}px) {
            margin-right: auto;
            margin-left: auto;
            width: 100%;
          }
        `}
`;

type RowProps = {
  noMargin?: boolean;
  noGutter?: boolean;
};

export const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ${breakpoints.tablet} {
    grid-template-columns: repeat(12, 1fr);
  }
  ${breakpoints.desktop} {
    grid-template-columns: repeat(12, 1fr);
  }

  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin: 0 ${spacing[4]}px;
      ${breakpoints.tablet} {
        margin: 0 ${spacing[9]}px;
      }
      ${breakpoints.desktop} {
        margin: 0 ${spacing[11]}px;
      }
    `}

  ${({ noGutter }) =>
    !noGutter &&
    css`
      column-gap: ${spacing[4]}px;
      ${breakpoints.tablet} {
        column-gap: ${spacing[6]}px;
      }
      ${breakpoints.desktop} {
        column-gap: ${spacing[9]}px;
      }
    `}
`;

type _4ColValues =
  | 'auto'
  | 'span 1'
  | 'span 2'
  | 'span 3'
  // 'span 4' is default if nothing is specified (mobile)
  | 'span 4'
  // '1' is default if no number is specified and this is a new line (starts at the first column)
  // '1 / span <x>' means start at the first column, which can be supersed by 'span <x>'. If it is needed to start on the next line, use another <Row /> instead
  | '2'
  | '2 / span 2'
  | '2 / span 3'
  | '3'
  | '3 / span 2'
  | '4';

type _12ColValues =
  | _4ColValues
  | 'span 4'
  | 'span 5'
  | 'span 6'
  | 'span 7'
  | 'span 8'
  | 'span 9'
  | 'span 10'
  | 'span 11'
  | 'span 12'
  // '1 / span <x>' means start at the first column, which can be supersed by 'span <x>'. If it is needed to start on the next line, use another <Row /> instead
  | '2 / span 4'
  | '2 / span 5'
  | '2 / span 6'
  | '2 / span 7'
  | '2 / span 8'
  | '2 / span 9'
  | '2 / span 10'
  | '2 / span 11'
  | '3 / span 3'
  | '3 / span 4'
  | '3 / span 5'
  | '3 / span 6'
  | '3 / span 7'
  | '3 / span 8'
  | '3 / span 9'
  | '3 / span 10'
  | '4 / span 2'
  | '4 / span 3'
  | '4 / span 4'
  | '4 / span 5'
  | '4 / span 6'
  | '4 / span 7'
  | '4 / span 8'
  | '4 / span 9'
  | '5'
  | '5 / span 2'
  | '5 / span 3'
  | '5 / span 4'
  | '5 / span 5'
  | '5 / span 6'
  | '5 / span 7'
  | '5 / span 8'
  | '6'
  | '6 / span 2'
  | '6 / span 3'
  | '6 / span 4'
  | '6 / span 5'
  | '6 / span 6'
  | '6 / span 7'
  | '7'
  | '7 / span 2'
  | '7 / span 3'
  | '7 / span 4'
  | '7 / span 5'
  | '7 / span 6'
  | '8'
  | '8 / span 2'
  | '8 / span 3'
  | '8 / span 4'
  | '8 / span 5'
  | '9'
  | '9 / span 2'
  | '9 / span 3'
  | '9 / span 4'
  | '10'
  | '10 / span 2'
  | '10 / span 3'
  | '11'
  | '11 / span 2'
  | '12';

export type ColProps = {
  mobile?: _4ColValues;
  tablet?: _12ColValues;
  desktop?: _12ColValues;
  xl?: _12ColValues;
  children: ReactNode;
  className?: string;
};

export const Col = styled.div<ColProps>`
  grid-column: ${({ mobile }) => mobile || 'span 4'};

  ${breakpoints.tablet} {
    grid-column: ${({ tablet }) => tablet || 'span 12'};
  }

  ${({ desktop }) =>
    desktop &&
    css`
      ${breakpoints.desktop} {
        grid-column: ${desktop};
      }
    `}

  ${({ xl }) =>
    xl &&
    css`
      ${breakpoints.xl} {
        grid-column: ${xl};
      }
    `}
`;
