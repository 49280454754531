import { SerializedStyles } from '@emotion/react';
import PropsComponent from '../../common-components/component-library/types/PropsComponent';
import { breakpointQueries } from '../styleguide';
import isSvgUrl from '../isSvgUrl';

/**
 * Contentful specic helper for images
 */

const IMAGE_WIDTHS = [80, 200, 400, 800, 1200, 1600];

type Device = 'desktop' | 'tablet' | 'mobile';
export type DeviceSpec = { desktop?: number; tablet?: number; mobile?: number };
const DEVICE_KEYS: Device[] = ['desktop', 'tablet', 'mobile'];

export type ImageFit = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

type ImageParams = {
  url: string;
  fileName?: string;
  spans?: DeviceSpec;
  aspectRatios?: DeviceSpec;
  fit?: ImageFit;
  maxWidth?: number | DeviceSpec;
  disableContentfulScaling?: boolean;
  lazyLoad?: boolean;
  fullBleed?: boolean;
};

type ImageProps = {
  alt: string;
  className?: string;
  css?: SerializedStyles;
};

export const getPicture = ({
  url,
  fileName,
  spans,
  aspectRatios,
  fit,
  maxWidth = 1440,
  disableContentfulScaling,
  lazyLoad,
  fullBleed,
}: ImageParams): PropsComponent<ImageProps> => {
  const fileExt = fileName?.split('.').pop();
  if (disableContentfulScaling || (fileExt && ['svg'].includes(fileExt)) || isSvgUrl(url)) {
    const SimpleImage = ({ alt, className }: ImageProps) => (
      // eslint-disable-next-line @next/next/no-img-element
      <img src={url} alt={alt} className={className} />
    );
    return SimpleImage;
  }
  const sizes = getSizes({ spans, maxWidth });
  const webpSources = getSourceTag({
    url,
    query: 'q=80',
    type: 'webp',
    sizes,
    aspectRatios,
    fit,
    fullBleed,
  });
  const { defaultType, defaultQuery, imgSrc } = getDefaultSetting(fileExt, url);
  const noWebpSource = getSourceTag({
    url,
    query: defaultQuery,
    type: defaultType,
    sizes,
    aspectRatios,
    fit,
    fullBleed,
  });

  const Picture = ({ alt, className, css }: ImageProps) => {
    const image = (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        srcSet={getSrcSet({
          url,
          query: defaultQuery,
          type: defaultType,
          aspectRatio: aspectRatios?.mobile,
          fullBleed,
        })}
        src={imgSrc}
        alt={alt}
        className={className}
        css={css}
        sizes={sizes}
        {...(lazyLoad && { loading: 'lazy' })}
      />
    );
    return (
      <>
        <picture style={{ display: 'block', width: '100%', height: '100%' }} css={css}>
          {webpSources}
          {noWebpSource}
          {image}
        </picture>
        <noscript>{image}</noscript>
      </>
    );
  };
  return Picture;
};

type Params = {
  url: string;
  query: string;
  type: string;
  fit?: ImageFit;
  maxWidth?: number;
  fullBleed?: boolean;
};

const getSourceTag = ({
  url,
  query,
  type,
  sizes,
  aspectRatios,
  fit,
  fullBleed,
}: Params & { sizes: string; aspectRatios?: DeviceSpec }) => {
  const mobileSource = (
    <source
      type={`image/${type}`}
      srcSet={getSrcSet({ url, query, type, fit, fullBleed })}
      sizes={sizes}
    />
  );
  if (!aspectRatios) {
    return mobileSource;
  }
  return DEVICE_KEYS.map((device) => {
    if (device === 'mobile') {
      return (
        <source
          key={device}
          type={`image/${type}`}
          srcSet={getSrcSet({
            url,
            query,
            type,
            aspectRatio: aspectRatios[device],
            fit,
            fullBleed,
          })}
          sizes={sizes}
        />
      );
    }
    const media = breakpointQueries[device];
    return (
      <source
        media={media}
        key={device}
        type={`image/${type}`}
        srcSet={getSrcSet({ url, query, type, aspectRatio: aspectRatios[device], fit, fullBleed })}
        sizes={sizes}
      />
    );
  });
};

const getSrcSet = ({
  url,
  query,
  type,
  aspectRatio = 1,
  fit,
  fullBleed,
}: Params & {
  aspectRatio?: number;
}) => {
  const WIDTHS = fullBleed ? [...IMAGE_WIDTHS, 4000] : IMAGE_WIDTHS;
  const srcSets = WIDTHS.map((width) => {
    const height = Math.floor(width / aspectRatio);
    // Scaling factor if image width/height is bigger than 4000.
    // If it's not it will be 1
    const ratioFromMax = 4000 / Math.max(width, height, 4000);
    const [w, h] = [Math.floor(width * ratioFromMax), Math.floor(height * ratioFromMax)];
    const fitted = fit ? `&fit=${fit}` : '';
    return `${url}?fm=${type}&${query}&w=${w}&h=${h}${fitted} ${width}w`;
  });
  return srcSets.join(',');
};

const getSizes = ({ spans, maxWidth }: { spans?: DeviceSpec; maxWidth?: number | DeviceSpec }) => {
  if (maxWidth && typeof maxWidth === 'object') {
    return DEVICE_KEYS.filter((device) => device in maxWidth)
      .map((device) => {
        if (device === 'mobile') {
          return getVwMaxWidth({ maxWidth: maxWidth[device] });
        }

        return `${breakpointQueries[device]} ${getVwMaxWidth({ maxWidth: maxWidth[device] })}`;
      })
      .join(',');
  }

  if (!spans) {
    return getVwMaxWidth({ maxWidth });
  }

  return DEVICE_KEYS.map((device) => {
    const vw = getVwMaxWidth({ span: spans[device], maxWidth });
    if (device === 'mobile') {
      return vw;
    }
    return `${breakpointQueries[device]} ${vw}`;
  }).join(',');
};

const getVwMaxWidth = ({ span, maxWidth }: { span?: number; maxWidth?: number }) => {
  const vw = getVw({ span });

  return maxWidth ? `min(${maxWidth}px, ${vw})` : vw;
};

const getVw = ({ span }: { span?: number }) => {
  if (!span) {
    return '100vw';
  }
  return `${Math.floor(100 / span)}vw`;
};

const getDefaultSetting = (
  fileExt: string | undefined,
  url: string,
): { defaultType: string; defaultQuery: string; imgSrc: string } => {
  // support transparent background for browsers that don't support webp
  return fileExt && ['png'].includes(fileExt)
    ? {
        defaultType: 'png',
        defaultQuery: 'q=90',
        imgSrc: `${url ? url : ''}?q=90`,
      }
    : {
        defaultType: 'jpg',
        defaultQuery: 'fm=jpg&fl=progressive&q=90',
        imgSrc: `${url ? url : ''}?fm=jpg&fl=progressive&q=90`,
      };
};
