import { css } from '@emotion/react';
import React, { CSSProperties, Suspense, lazy, useMemo } from 'react';
import { breakpoints } from '../../utils/styleguide';

export type IconNames =
  | 'volume-mute'
  | 'volume-on'
  | 'hamburger'
  | 'dashboard'
  /**
   * @deprecated
   */
  | 'milkywire-full'
  | 'milkywire-symbol'
  | 'milkywire-wordmark'
  | 'milkywire-logo-brand'
  | 'milkywire-mastercard-logo'
  | 'powered-by-milkywire'
  /**
   * @deprecated
   */
  | 'milkywire-logo-on-yellow'
  | 'facebook'
  | 'facebook-rounded'
  | 'gaming-controller'
  | 'twitter'
  | 'instagram'
  | 'linkedin'
  | 'lightning'
  | 'youtube'
  | 'mail'
  | 'long-arrow'
  | 'long-arrow-down'
  | 'x-filled'
  | 'x-cross'
  | 'x-ol'
  | 'lock'
  | 'lock-filled'
  | 'lock-check-filled'
  | 'check-filled'
  | 'check-square-filled'
  | 'chevron-down'
  | 'chevron-down-outline'
  | 'chevron-s-down-outline'
  | 'chevron-right'
  | 'chevron-s-up'
  | 'chevron-s-down'
  | 'clock'
  | 'calendar'
  | 'containers-and-cloud'
  | 'credit-card'
  | 'camera'
  | 'caution'
  | 'video'
  | 'film'
  | 'download'
  | 'upload'
  | 'upload-cloud'
  | 'file-text'
  | 'fog'
  | 'x-symbol'
  | 'logos/pangaia'
  | 'bee'
  | 'bee-vertical'
  | 'pangaia-bee'
  | 'pangaia-bee-text'
  | 'pangaia-bee-text-with-trail'
  | 'trail4'
  | 'pangaia-bee-trail'
  | 'map-marker-filled'
  | 'logos/stripe'
  | 'pangaia-bee-trail2'
  | 'stripe-icon'
  | 'search'
  | 'logos/klarna'
  | 'sustainable-development-goals'
  | 'sdg-square'
  | 'arrow-left'
  | 'arrow-left-long'
  | 'arrow-right'
  | 'arrow-long-right'
  | 'arrow-down'
  | 'star'
  | 'apple-pay'
  | 'american-express'
  | 'american-express-solid'
  | 'mastercard'
  | 'mastercard-solid'
  | 'mastercard-ppc-logo-lockup'
  | 'mastercard-ppc-logo-lockup-color'
  | 'visa'
  | 'visa-solid'
  | 'paypal-solid'
  | 'default-card-icon'
  | 'archive'
  | 'solid-on-light'
  | 'external-link'
  | 'bin'
  | 'check'
  | 'checkmark'
  | 'rocket'
  | 'help-circle-fill'
  | 'right-up-circle-fill'
  | 'right-circle-fill'
  | 'right-down-circle-fill'
  | 'pause'
  | 'play'
  | 'plus'
  | 'edit'
  | 'info-outline'
  | 'info-outline2'
  | 'thumbs-up'
  | 'thumbs-up-fill'
  | 'thumbs-down'
  | 'thumbs-down-fill'
  | 'tree'
  | 'user-group'
  | 'play-video'
  | 'x2'
  | 'coin-2'
  | 'bcg-logo'
  | 'gold-standard-logo'
  | 'sbt-logo'
  | 'wwf-logo'
  | 'logos/wri-light'
  | 'logos/wri-dark'
  | 'logos/ci-light'
  | 'logos/ci-dark';

type Props = {
  name: IconNames;
  fill?: string;
  stroke?: string;
  size?: number | string;
  desktopSize?: number | string;
  title?: string;
  onClick?: (event: React.MouseEvent) => void;
  style?: CSSProperties;
  height?: number | string;
  width?: number | string;
};

const Icon = ({ name, desktopSize, size, fill, stroke, ...other }: Props) => {
  const IconToRender = useMemo(() => lazy(() => import(`../../assets/svg/${name}.svg`)), [name]);

  if (!IconToRender) {
    // eslint-disable-next-line no-console
    console.warn(`Could not find icon with name: ${name}`);
    return null;
  }

  return (
    <Suspense fallback={null}>
      <IconToRender
        css={css`
          font-size: ${size}px;
          ${breakpoints.tablet} {
            font-size: ${desktopSize || size}px;
          }

          path {
            fill: ${fill};
            stroke: ${stroke};
          }

          circle {
            fill: ${fill};
            stroke: ${stroke};
          }
        `}
        {...other}
      />
    </Suspense>
  );
};

export default Icon;
