import Head from 'next/head';
import { TypeMetaHeader } from '../../../@types/generated';

export type MetaHeaderProps = {
  seoDescription: string;
  seoTitle: string;
  socialDescription: string;
  socialImageUrl: string;
  socialImageHeight: number;
  socialImageWidth: number;
  socialTitle: string;
  canonicalLink: string;
};

export type MetaHeaderOverrides = Partial<{
  seoDescription: string;
  seoTitle: string;
  socialDescription: string;
  socialTitle: string;
  socialImageUrl: string;
  canonicalLink: string;
}>;

export const transformMetaHeaderType = ({
  metaHeader,
  overrides,
  fallbacks,
}: {
  metaHeader?: TypeMetaHeader;
  overrides?: MetaHeaderOverrides;
  fallbacks?: Partial<MetaHeaderProps>;
}): MetaHeaderProps | undefined => {
  if (!metaHeader && !fallbacks && !overrides) {
    return undefined;
  }

  return {
    seoDescription:
      overrides?.seoDescription ||
      metaHeader?.fields.seoDescription ||
      fallbacks?.seoDescription ||
      '',
    seoTitle: overrides?.seoTitle || metaHeader?.fields.seoTitle || fallbacks?.seoTitle || '',
    socialDescription:
      overrides?.socialDescription ||
      metaHeader?.fields.socialDescription ||
      fallbacks?.socialDescription ||
      '',
    socialImageUrl:
      overrides?.socialImageUrl ||
      metaHeader?.fields.socialImage?.fields.file?.url ||
      fallbacks?.socialImageUrl ||
      '',
    socialImageHeight: metaHeader?.fields.socialImageHeight || fallbacks?.socialImageHeight || 0,
    socialImageWidth: metaHeader?.fields.socialImageWidth || fallbacks?.socialImageWidth || 0,
    socialTitle:
      overrides?.socialTitle || metaHeader?.fields.socialTitle || fallbacks?.socialTitle || '',
    canonicalLink:
      overrides?.canonicalLink ||
      metaHeader?.fields.canonicalLink ||
      fallbacks?.canonicalLink ||
      '',
  };
};

type Props = {
  metaHeader: MetaHeaderProps | undefined;
};

const MetaHeader = ({ metaHeader }: Props) => {
  const {
    seoDescription,
    seoTitle,
    socialDescription,
    socialImageUrl,
    socialImageHeight,
    socialImageWidth,
    socialTitle,
    canonicalLink,
  } = metaHeader || {};

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta key="description" name="description" content={seoDescription} />
      <meta key="og:title" property="og:title" content={socialTitle} />
      <meta key="og:description" property="og:description" content={socialDescription} />
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      {socialImageUrl && (
        <meta key="og:image" property="og:image" content={`https:${socialImageUrl}`} />
      )}
      {socialImageUrl && socialImageWidth && (
        <meta key="og:image:width" property="og:image:width" content={String(socialImageWidth)} />
      )}
      {socialImageUrl && socialImageHeight && (
        <meta
          key="og:image:height"
          property="og:image:height"
          content={String(socialImageHeight)}
        />
      )}
      <script
        key="schema.org"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'organization',
            name: seoTitle,
            image: [`https:${socialImageUrl}`],
            description: seoDescription,
          }),
        }}
      />
    </Head>
  );
};

export default MetaHeader;
