/**
 * NOTE: this is a helper function to be used with pagination requests in the galaxy client.
 * We need to do this to not get CORS issues locally (e.g. when running against staging proxy)
 */
export const extractPaginatedUrl = (pageParam: string, apiUrl: string) => {
  const url = new URL(pageParam);

  // remove the leading slash since we duplicate it otherwise with the apiUrl
  const paginatedUrl = `${apiUrl}${url.pathname.substring(1)}${url.search}`;
  return paginatedUrl;
};
