import { TypePageSection } from '../../../../@types/generated';

const orientedMediaToCompatibleProps = ({
  orientedMedia,
  image,
}: Pick<TypePageSection['fields'], 'orientedMedia' | 'image'>) => {
  return {
    image: orientedMedia?.fields.imageLandscape || orientedMedia?.fields.imagePortrait || image,
    imageLandscape: orientedMedia?.fields.imageLandscape,
    imagePortrait: orientedMedia?.fields.imagePortrait,
    videoIdLandscape: orientedMedia?.fields.videoIdLandscape,
    videoIdPortrait: orientedMedia?.fields.videoIdPortrait,
    fixedHeight: orientedMedia?.fields.fixedHeight,
    dynamicHeight: orientedMedia?.fields.dynamicHeight,
    maxWidth: orientedMedia?.fields.maxWidth,
  };
};

export default orientedMediaToCompatibleProps;
