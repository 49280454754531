import { css } from '@emotion/react';
import type { IntersectKeys } from 'ts-toolbelt/out/Object/IntersectKeys';
import type { ListOf } from 'ts-toolbelt/out/Union/ListOf';
import { colorsKurppa } from './styleguideKurppa';

export type Color = keyof typeof colors;

export const brandColors = {
  black: `#111111`,
  petrol: colorsKurppa.green400,
  yellow: colorsKurppa.grey02,
  red: `#FF5353`,
  blue: colorsKurppa.purple200,
  green: colorsKurppa.green400,
  peach: colorsKurppa.yellow400,
};
const functionalColors = {
  error: `#E22F35`,
  info: `#663FBA`,
  success: `#29BE88`,
  warning: `#EF6C00`,
};
const accentTintColors = {
  blueDark: colorsKurppa.purple400,
  blueDarker: colorsKurppa.purple600,
  blueLight: colorsKurppa.purple200,
  blueLighter: colorsKurppa.purple100,
  greenDark: colorsKurppa.green600,
  greenLight: colorsKurppa.green200,
  redDark: `#BA1A20`,

  yellowDark: colorsKurppa.orange600,
  yellowLight: colorsKurppa.orange100,
};
/**
 * Unused in code but used in contentful types
 */
const unusedColors = {
  brown: '',
  lightBlue: '',
};

const oldColors = {
  ...brandColors,
  ...functionalColors,
  ...accentTintColors,
  ...unusedColors,
  lightGreen: colorsKurppa.grey01,
  white: `#FFFFFF`,
  whiteTransparent: colorsKurppa.grey01,
  whiteTransparent20: colorsKurppa.grey01,
  whiteTransparent70: colorsKurppa.grey01,
  whiteTransparentSemi: colorsKurppa.grey03,
  grey: colorsKurppa.grey03,
  gray: colorsKurppa.grey03,
  darkGrey: colorsKurppa.grey04,
  darkGray: colorsKurppa.grey04,
  lightGrey: colorsKurppa.grey02,
  lightGray: colorsKurppa.grey02,
  brightGrey: colorsKurppa.grey01,
  brightGray: colorsKurppa.grey01,
  lightRed: colorsKurppa.red100,
};

export const colors = {
  ...oldColors,
  ...colorsKurppa,
};

/**
 * Type check to make sure there is no accidental override
 */
type CommonKeys = ListOf<IntersectKeys<typeof colorsKurppa, typeof oldColors>>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const overrideCheck: CommonKeys = [];

export const breakpointValues = {
  tablet: 768,
  desktop: 1025,
  xl: 1440,
};

// TODO: Update with breakpoints from design
export const breakpointQueries = {
  tablet: `(min-width:${breakpointValues.tablet}px)`,
  desktop: `(min-width:${breakpointValues.desktop}px)`,
  xl: `(min-width:${breakpointValues.xl}px)`,
};

export const breakpoints = {
  tablet: `@media ${breakpointQueries.tablet}`,
  desktop: `@media ${breakpointQueries.desktop}`,
  xl: `@media ${breakpointQueries.xl}`,
};

/**
 * @example
 * index 0  1  2   3   4   5   6   7   8   9   10  11
 * px    4  8  12  16  24  32  40  48  56  64  80  120
 */
// index:               0  1  2   3   4   5   6   7   8   9   10  11
export const spacing = [4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 80, 120];

export const NAVBAR_HEIGHT_S = 56;
export const NAVBAR_HEIGHT_L = 80;

const DESKTOP_SECTION_MIN_HEIGHT = 720;
export const DESKTOP_SECTION_MIN_HEIGHT_CSS = css`
  ${breakpoints.desktop} {
    min-height: ${DESKTOP_SECTION_MIN_HEIGHT}px;
  }
`;
export const DESKTOP_SECTION_FIXED_HEIGHT_CSS = css`
  ${breakpoints.desktop} {
    height: ${DESKTOP_SECTION_MIN_HEIGHT}px;
  }
`;

export const transitionSpeeds = {
  shortest: '150ms',
  shorter: '200ms',
  short: '250ms',
  // most basic recommended timing
  standard: '300ms',
  // this is to be used in complex animations
  complex: '375ms',
  // recommended when something is entering screen
  enteringScreen: '225ms',
  // recommended when something is leaving screen
  leavingScreen: '195ms',
};
